import axios from 'axios';
// import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
// baseURL: process.env.baseConfig['baseUrl'],
import MyStorage from 'utils/mystorage'

const instance = axios.create({
    headers: { 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*' },
    timeout: 15000,
    adapter: axios.defaults.adapter,
    transformResponse: axios.defaults.transformResponse.concat((data) => {
        if(typeof window !== 'undefined') {
            try {
                var myStorage = new MyStorage()
                // tak trzymamy wersje domeny, jesli instruktor ja zmieni to praktycznie od razu user ja dostanie
                var ver = myStorage.get('di_ver')
                if(!ver || ver < data.domain_updated_ts) {
                    myStorage.del('di')
                    myStorage.set('di_ver', data.domain_updated_ts)
                }
            } catch (error) {
                console.log('axios oops', error)
            }
        }
        return data;
      })
})

// instance.interceptors.request.use((config) => {
//     return config
// }, error => {
//     console.log('error!!!', error)
//     return Promise.reject(error)
// })

instance.interceptors.response.use((response) => {
    return response
}, error => {
    if(error?.response?.status === 408 || error?.code === 'ECONNABORTED') {
        console.log('A timeout ocurred on url', error.config.url)
        alert("Uh-oh! A timeout ocurred. Please check your internet connection. If your internet is working, and you still cannot connect, then just please come back later.")
    }
    return Promise.reject(error)
})
// .catch((err) => {
//     console.log('object', object)
// })

export default instance;
